<template>
  <div class="table-container">
    <div class="table-header">
      {{ table_header }}
    </div>
    <b-table
      striped
      hover
      :items="items"
      :fields="
        [{ key: 'section', label: this.column_name, sortable: true }].concat(
          fields
        )
      "
      small
      responsive
      thead-class="custom-header small"
      table-class="simple-report-table small"
      bordered
    >
      <template v-slot:head()="data">
        <div class="custom-table-header">
          <template v-if="data.field.label == 'PERFIL DE EGRESO'">
            <div>
              {{
                $getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil De Egreso"
                ).toUpperCase()
              }}
            </div>
          </template>
          <template
            v-else-if="
              data.field.label != 'N°' &&
              data.field.label != 'AÑO DE INGRESO' &&
              data.field.label != 'IDENTIFICADOR' &&
              data.field.label != 'APELLIDO' &&
              data.field.label != 'NOMBRE' &&
              data.field.label != 'PERFIL DE EGRESO'
            "
          >
            <div :id="`assessment-header-${data.field.label.split(',')[0]}`">
              {{ data.field.label.split(",")[0] }}
            </div>
            <b-tooltip
              :target="`assessment-header-${data.field.label.split(',')[0]}`"
              variant="secondary"
              placement="top"
              custom-class="custom-tooltip"
              >{{ data.field.label.split(",")[1] }}</b-tooltip
            >
          </template>
          <template v-else>
            <div>
              {{ data.field.label }}
            </div>
          </template>
        </div>
      </template>
      <template v-slot:cell()="data">
        <template>
          <div
            v-if="
              chartdata.extra_data &&
              data.field.label != 'N°' &&
              data.field.label != 'AÑO DE INGRESO' &&
              data.field.label != 'IDENTIFICADOR' &&
              data.field.label != 'APELLIDO' &&
              data.field.label != 'NOMBRE' &&
              data.field.label != 'PERFIL DE EGRESO'
            "
            :class="{
              'bg-red':
                data.value <=
                chartdata.extra_data.achievement_ranges[0][2] + '%',
              'bg-yellow':
                data.value >=
                  chartdata.extra_data.achievement_ranges[1][1] + '%' &&
                data.value <=
                  chartdata.extra_data.achievement_ranges[1][2] + '%',
              'bg-green':
                data.value >=
                  chartdata.extra_data.achievement_ranges[2][1] + '%' ||
                data.value ==
                  chartdata.extra_data.achievement_ranges[2][2] + '%',
              'bg-transparent': data.value == '-',
            }"
          >
            {{ data.value }}
          </div>
          <div v-else>
            {{ data.value }}
          </div>
        </template>
      </template>
    </b-table>
    <template v-if="!chartdata.datasets.length">
      <div class="empty-dataset-alert">
        <b-icon icon="exclamation-circle" class="mr-1"></b-icon> No hay datos
        para mostrar.
      </div>
    </template>
    <template v-if="chartdata.extra_data">
      <div class="report-ranges-caption">
        <div class="report-ranges-header">RANGOS DE LOGROS</div>
        <template
          v-for="(grade_range, index) in chartdata.extra_data
            .achievement_ranges"
        >
          <div class="report-range" :key="grade_range.id">
            <span class="range-label">
              {{ chartdata.extra_data.achievement_ranges[index][0] }}
            </span>
            <div
              :class="{
                'range-value-red': index == 0,
                'range-value-yellow': index == 1,
                'range-value-green': index == 2,
              }"
            >
              {{ chartdata.extra_data.achievement_ranges[index][1] + "%" }} -
              {{ chartdata.extra_data.achievement_ranges[index][2] + "%" }}
            </div>
          </div>
        </template>
      </div>
    </template>
    <template>
      <download-excel
        class="btn btn-primary mt-2"
        :data="itemsXLS"
        :fields="jsonFields"
        :worksheet="`POR ${$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        ).toUpperCase()}`"
        :name="`Reporte Assessment por ${$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        )}.xls`"
        :header="headerArray"
        :footer="footerArray"
      >
        Descargar XLS
      </download-excel>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentMatter",
  props: ["chartdata", "selectors"],
  data() {
    return {
      section_name: "",
      label_tooltip: "",
      column_name: "N°",
    };
  },
  computed: {
    ...mapGetters({ institution: "getInstitution" }),
    table_header() {
      return (
        "REPORTE DE ASSESSMENT POR " +
        this.$getVisibleNames(
          "mesh.egressprofilematter",
          false,
          "Asignatura"
        ).toUpperCase()
      );
    },
    items() {
      let list = [];
      const datasets = this.chartdata.datasets;
      const labels = this.chartdata.labels;
      datasets.forEach((elements) => {
        let row = { section: elements.label };
        for (let index = 0; index < elements.data.length; index++) {
          let label = labels[index][0];
          let content = labels[index][1];
          let weighing = labels[index][2];
          if (index > 4 && label != "PERFIL DE EGRESO") {
            label = label + " - " + weighing + "%" + "," + content;
          }
          const data = elements.data[index];
          if (!isNaN(data) && index > 4 && data != null) {
            row[label] = data + "%";
          } else if (data == null) {
            row[label] = "-";
          } else {
            row[label] = data;
          }
        }
        list.push(row);
      });
      return list;
    },
    itemsXLS() {
      let list = [];
      const datasets = this.chartdata.datasets;
      const labels = this.chartdata.labels;
      datasets.forEach((elements) => {
        let row = { section: elements.label };
        for (let index = 0; index < elements.data.length; index++) {
          let label = labels[index][0];
          // let content = labels[index][1];
          let weighing = labels[index][2];
          if (index > 4 && labels[index][0] != "PERFIL DE EGRESO") {
            // label = label + " - " + weighing + "%" + "," + content;
            label = label + " - " + weighing + "%";
          }
          const data = elements.data[index];
          if (!isNaN(data) && index > 4 && data != null) {
            row[label] = data + "%";
          } else if (data == null) {
            row[label] = "";
          } else {
            row[label] = data;
          }
        }
        list.push(row);
      });
      return list;
    },
    fields() {
      let list = [];
      for (let index = 0; index < this.chartdata.labels.length; index++) {
        let column_header = this.chartdata.labels[index][0];
        let content = this.chartdata.labels[index][1];
        let weighing = this.chartdata.labels[index][2];
        if (
          index > 4 &&
          this.chartdata.labels[index][0] != "PERFIL DE EGRESO"
        ) {
          // column_header = column_header + " - " + weighing + "%";
          column_header =
            column_header + " - " + weighing + "%" + "," + content;
        }
        if (!this.institution.show_ra_macro) {
          if (!column_header.includes("RAMACRO")) {
            list.push(column_header);
          }
        } else {
          list.push(column_header);
        }
      }
      return list;
    },
    footers() {
      let list = [];
      for (let index = 0; index < this.chartdata.labels.length; index++) {
        let counter = 0;
        this.items.forEach((section) => {
          counter += section[this.chartdata.labels[index]];
        });
        list.push(counter);
      }
      return list;
    },
    jsonFields() {
      let json_fields = { "N°": "section" };
      this.fields.forEach((label, index) => {
        if (index > 4) {
          label = label.substring(0, label.indexOf(","));
          json_fields[label] = label;
        }
        json_fields[label] = label;
      });
      return json_fields;
    },
    jsonFooter() {
      let json_footer = { section: "Totales" };
      for (let index = 0; index < this.chartdata.labels.length; index++) {
        const label = this.chartdata.labels[index];
        const data = this.footers[index];
        json_footer[label] = data;
      }
      return json_footer;
    },
  },
  methods: {
    headerArray() {
      let list = "";
      list +=
        "<tr>" +
        `<th colspan='${
          this.chartdata.labels.length + 1
        }' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
        this.table_header +
        "</th>" +
        "</tr>";
      if (
        this.selectors.selected_campuses.length &&
        this.selectors.selected_campuses.length > 0
      ) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "CAMPUS: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_campuses[0].name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_egress_profiles) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil De Egreso"
          ).toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_egress_profiles[0].professional_title.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_periods) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames("mesh.period", false, "Período").toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_periods[0].name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_levels) {
        list +=
          "<tr>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          "NIVEL: " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle; text-align: center'>" +
          "<b>" +
          this.selectors.selected_levels[0].name +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_matters.length > 0) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ).toUpperCase() +
          ": " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_matters[0].matter_name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      if (this.selectors.selected_sections.length > 0) {
        list +=
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          ).toUpperCase() +
          ": " +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important'>" +
          "<b>" +
          this.selectors.selected_sections[0].name.toUpperCase() +
          "</b>" +
          "</td>";
      }
      return list;
    },
    footerArray() {
      let list = "";
      if (this.chartdata.extra_data) {
        let ranges = this.chartdata.extra_data.achievement_ranges;
        list +=
          "<tr>" +
          `<th colspan='8' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
          "RANGOS DE NOTAS" +
          "</th>" +
          "</tr>" +
          "<tbody>" +
          "<tr>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          ranges[0][0].toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important; text-align: center'>" +
          "<b>" +
          ranges[0][1] +
          "%" +
          " - " +
          ranges[0][2] +
          "%" +
          "</b>" +
          "</td>" +
          "<td colspan='1' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          ranges[1][0].toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle; text-align: center'>" +
          "<b>" +
          ranges[1][1] +
          "%" +
          " - " +
          ranges[1][2] +
          "%" +
          "</b>" +
          "</td>" +
          "<td colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>" +
          "<b>" +
          ranges[2][0].toUpperCase() +
          "</b>" +
          "</td>" +
          "<td style='border: .5pt solid black; vertical-align: middle !important; text-align: center'>" +
          "<b>" +
          ranges[2][1] +
          "%" +
          " - " +
          ranges[2][2] +
          "%" +
          "</b>" +
          "</td>" +
          "</tr>" +
          "</tbody>";

        if (this.chartdata.labels) {
          list +=
            `<th colspan='8' style='background-color: #9BC2E6 !important; vertical-align: middle; border: .5pt solid black' >` +
            "</th>" +
            "<tr>" +
            "<th colspan='2' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>RA - CE</th>" +
            "<th colspan='6' style='background-color: #BDD7EE !important; vertical-align: middle; border: .5pt solid black'>ENUNCIADO</th>" +
            "</tr>";
          for (let index = 0; index < this.chartdata.labels.length; index++) {
            let content = this.chartdata.labels[index];
            if (index > 4) {
              list +=
                "<tr>" +
                "<td colspan='2' style='vertical-align: middle; border: .5pt solid black; text-align: justify !important; font-weight: bold'>" +
                content[0] +
                "</td>" +
                "<td colspan='6' style='vertical-align: middle; border: .5pt solid black; text-align: justify !important'>" +
                content[1] +
                "</td>" +
                "</tr>";
            }
          }
        }
        return list;
      }
    },
  },
  mounted() {
    if (this.section) {
      this.$store
        .dispatch(names.FETCH_SECTION, this.section)
        .then((response) => {
          this.section_name = response.name;
        });
    } else {
      this.section_name = "SIN SELECCIONAR";
    }
  },
};
</script>

<style scoped>
.table-container {
  scroll-behavior: auto;
}
.table-thead {
  font-size: 5pt;
}
.table-header {
  background: var(--primary-color);
  font-weight: bold;
  padding: 0.2em;
}
.table-caption {
  display: flex;
}
.student-header,
.achievement-header {
  font-size: 11pt;
  padding: 0.2em;
  background: var(--primary-hover-color);
  overflow-x: hidden;
}
.student-header {
  min-width: 40%;
}
.achievement-header {
  width: 70%;
}
.table-foot {
  background-color: darkgrey;
}
.table-responsive >>> .table {
  text-align: center !important;
}
.table-container >>> .custom-header {
  background: var(--primary-hover-color) !important;
}
.table-container >>> .table thead th {
  vertical-align: middle !important;
}
.bg-red,
.bg-yellow,
.bg-green {
  height: 100% !important;
  vertical-align: middle;
  bottom: 50% !important;
  padding-top: 5px;
}
.bg-red {
  background-color: #f19292 !important;
}
.bg-yellow {
  background-color: #fff1ac !important;
}
.bg-green {
  background-color: #bbeaa6 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.report-ranges-caption {
  width: 100%;
  border: 1px solid var(--primary-hover-color);
}
.report-ranges-header {
  text-align: left;
  padding: 0.5em 1em;
  padding-left: 2em;
  background: var(--primary-hover-color);
  color: var(--secondary-font-color);
  font-weight: bold;
}
.report-range {
  display: flex;
  margin-left: 0;
  padding: 0.5em 4em;
}
.range-label {
  text-align: left;
  width: 15%;
}
.report-range .range-value-red {
  background: #f19292;
  width: 15%;
}
.report-range .range-value-yellow {
  background: #fff1ac;
  width: 15%;
}
.report-range .range-value-green {
  background: #bbeaa6;
  width: 15%;
}
.empty-dataset-alert {
  margin: 2em 0;
  font-size: 13pt;
}
</style>